<template lang="pug">
    .diagnostics
        .item(
            v-for="item in diagnosticsData",
            :key="item.text"
        )
            img(:src="require(`../../../../../assets/images/articles/4/diagnostics/${item.icon}.svg`)", :alt="item.text")
            ui-paragraph.text(v-html="item.text")
</template>

<script>
import UiParagraph from '../../../../ui/Paragraph.vue';

export default {
    name: 'diagnostics',
    components: {
        UiParagraph,
    },
    computed: {
        diagnosticsData() {
            /* eslint-disable max-len */
            return [
                {
                    icon: '1',
                    text: '<strong>Выявление клинических проявлений заболевания</strong><br/> (кашель, слабость, потливость, потеря веса, повышение температуры тела, боль)',
                },
                {
                    icon: '2',
                    text: '<strong>Физикальное обследование пациента</strong><br/> (осмотр, выслушивание легких и&nbsp;т.д.)',
                },
                {
                    icon: '3',
                    text: '<strong>Измерение функциональных характеристик легких</strong>',
                },
                {
                    icon: '4',
                    text: '<strong>Лабораторные исследования крови и/или другого биоматериала</strong><br/> (моча и&nbsp;др.)',
                },
                {
                    icon: '5',
                    text: '<strong>Лучевое обследование органов грудной клетки</strong><br/> (флюорография, рентгенография, компьютерная томография)',
                },
                {
                    icon: '6',
                    text: '<strong>Исследование тканей из&nbsp;пораженного органа</strong><br/> (морфологическая диагностика)',
                },
            ];
            /* eslint-enable max-len */
        },
    },
};
</script>

<style lang="scss" scoped>
.diagnostics {
    width: 100%;
    max-width: 750px;
    margin: rem(45) auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: flex-start;

    .item {
        width: 45%;
        margin-bottom: rem(32);

        img {
            width: rem(100);
            margin: 0 auto;
            display: block;
            object-fit: contain;
        }

        .text {
            width: 100%;
            margin-top: rem(14);
            text-align: center;
        }
    }

    @media (--viewport-tablet) {
        max-width: unset;
        margin: rem-mobile(32) auto;
        padding: 0 var(--offset-x);
        display: block;

        .item {
            width: 100%;
            margin-bottom: rem-mobile(56);

            img {
                width: rem-mobile(100);
                margin: 0 auto;
                display: block;
                object-fit: contain;
            }

            .text {
                width: 100%;
                margin-top: rem-mobile(14);
                text-align: center;
            }
        }
    }
}
</style>
